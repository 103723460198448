<template>
	<div class="theme-dashboard-view">
		<div class="d-flex justify-content-between">
			<h1 v-show="!edit">{{ $t("category_create_title") }}</h1>
			<h1 v-show="edit">{{ $t("category_update_title") }}</h1>
			<div
				class="buttons d-flex justify-content-end"
				style="height: 50px; max-width: 300px; min-width: 200px"
			>
				<!-- Submit Button -->
				<button
					class="theme-button mx-2"
					style="max-width: 100px"
					@click="this.edit ? updateCategory() : createCategory()"
				>
					{{ $t("category_submit_button") }}
				</button>

				<!-- Delete Button -->
				<button
					v-show="edit"
					class="theme-button mx-2 bg-danger text-light"
					@click="deleteCategory"
				>
					<!-- {{ $t("category_delete_button") }} -->
					<i class="fa fa-trash" aria-hidden="true"></i>
				</button>
			</div>
		</div>

		<div class="my-4">
			<!-- Loading Indicator -->
			<div class="d-flex justify-content-center">
				<LoadingIndicator :active="loading" />
			</div>
			<!-- Loading Status -->
			<div v-show="load_status">
				<div class="justify-content-center text-center text-danger my-4">
					<h4>{{ load_status }}</h4>
				</div>
			</div>
			<!-- Two columns for Image + form input -->
			<div class="d-flex flex-wrap">
				<!-- Category Image -->
				<div class="col-lg-6 col-12">
					<!-- {{ image }} -->
					<label for=""
						><strong>{{ $t("category_fields_image") }}</strong></label
					>
					<img
						:src="image"
						class="border"
						style="max-width: 100%; min-width: 100%; min-height: 300px"
					/>
					<input type="file" @change="setImage" accept="image/*" />
					<div class="form-group">
						<br />
					</div>
				</div>
				<!-- Form -->
				<div class="col-lg-6 col-12">
					<!-- Category Name -->
					<div class="form-group">
						<label for=""
							><strong>{{ $t("category_fields_name") }}</strong></label
						>

						<br />
						<input
							type="text"
							v-model="name"
							:placeholder="$t('category_fields_name_placeholder')"
							class="w-100 p-2"
						/>
					</div>

					<!-- Category POS Name -->
					<div class="form-group">
						<label for=""
							><strong>{{ $t("category_fields_pos_name") }}</strong></label
						>

						<br />
						<input
							type="text"
							v-model="pos_name"
							:placeholder="$t('category_fields_pos_name_placeholder')"
							class="w-100 p-2"
						/>
					</div>
					<!-- Category Description -->
					<div class="form-group">
						<label for=""
							><strong>{{ $t("category_fields_desc") }}</strong></label
						>
						<br />
						<textarea
							v-model="description"
							:placeholder="$t('category_fields_desc_placeholder')"
							rows="5"
							style="resize: none"
							class="w-100 p-2"
						/>
					</div>

					<!-- Category Tags -->
					<div class="form-group">
						<strong>{{ $t("category_fields_tags") }}:</strong>
						<div class="p-2 d-flex flex-wrap">
							<div
								class="theme-background-secondary border rounded d-flex mx-2"
								v-for="(tag, index) in tags"
								:key="index"
							>
								<span class="p-2 mx-1">
									{{ tag }}
								</span>

								<span
									class="px-1"
									@click="removeTag(index)"
									style="cursor: pointer"
									>x</span
								>
							</div>
						</div>
						<input
							type="text"
							@keydown.enter="addTag"
							v-model="tagText"
							:placeholder="$t('category_fields_tags_placeholder')"
							class="w-100 p-2"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";

// Services
import CategoryService from "@/services/category.js";

export default {
	name: "Category-Create",
	components: {
		LoadingIndicator,
	},
	data() {
		return {
			// Form data
			name: "",
			pos_name: "",
			description: "",
			image: "",
			tags: [],

			tagText: "",
			loading: false,
			load_status: "",
			edit: false,
		};
	},
	methods: {
		addTag() {
			this.tags.push(this.tagText);
			this.tagText = "";
		},
		removeTag(index) {
			this.tags.splice(index, 1);
			console.log(this.tags);
		},

		// Set the image
		setImage(e) {
			const selectedImage = e.target.files[0];
			const reader = new FileReader();

			// Set itemImage once processing is done
			reader.onload = (e) => {
				this.image = e.target.result;
			};

			reader.readAsDataURL(selectedImage);
		},

		createCategory() {
			this.loading = true;
			this.load_status = "";

			CategoryService.createCategory({
				name: this.name,
				pos_name: this.pos_name,
				description: this.description,
				img: this.image,
				tags: this.tags,
			}).then(
				() => {
					this.loading = false;
					this.load_status = "Category Created";
					this.resetForm();
				},
				(error) => {
					this.loading = false;
					this.load_status =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		updateCategory() {
			this.loading = true;
			this.load_status = "";

			CategoryService.updateCategory(this.$route.params.id, {
				name: this.name,
				description: this.description,
				img: this.image,
				tags: this.tags,
			}).then(
				() => {
					this.loading = false;
					this.load_status = "Category Updated";
				},
				(error) => {
					this.loading = false;
					this.load_status =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		deleteCategory() {
			this.loading = true;
			this.load_error = "";

			CategoryService.deleteCategory(this.$route.params.id).then(
				() => {
					this.loading = false;
					this.$router.push("/category");
				},
				(error) => {
					this.loading = false;
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		resetForm() {
			this.name = "";
			this.pos_name = "";
			this.description = "";
			this.image = "";
			this.tags = [];
			this.tagText = "";
		},
	},
	mounted() {
		if (this.$route.params.id != null) {
			this.loading = true;
			this.load_error = "";
			this.edit = true;
			CategoryService.getSingleCategory(this.$route.params.id).then(
				(response) => {
					console.log(response.data);
					this.loading = false;
					this.name = response.data.data.category.name;
					this.pos_name = response.data.data.category.pos_name;
					this.description = response.data.data.category.description;
					this.image = response.data.data.category.img;
					this.tags = response.data.data.category.tags;
				},
				(error) => {
					this.loading = false;
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					this.$router.push("/category/");
				}
			);
		}
	},
};
</script>
<style scoped>
label {
	margin-right: 2vh;
}

.form-group {
	padding: 1vh;
}
</style>
